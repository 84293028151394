/* !
 * index.js v1.0.0
 * Copyright, Connecty Inc.
 */
((FUNCTIONS) => {
	const a = 'is-active';
	const v = 'is-visible';

	const newsSlide = () => {
		if (document.querySelector('.js-consulTop-slide01')) {
			let slider = document.querySelector('.js-consulTop-slide01');
			let slideCount = slider.querySelectorAll('.splide__slide').length;

			if (slideCount > 1) {
				document.querySelectorAll('.js-consulTop-slide01').forEach(ele => {
					const splide = new Splide(ele, {
						type: 'loop',
						perPage: 3,
						autoplay: true,
						autoWidth: true,
						focus: "center",
						updateOnMove: true,
						interval: 5500,
						speed: 500,
						arrows: true,
						pagination: true,
						paginationKeyboard: true,
						perMove: 1,
						breakpoints: {
							1024: {
								perPage: 3,
							},
						}
					}).mount();
				});
				if (document.querySelector('.js-consulTop-slide01 .splide__arrow')) {
					const arrowpos = () => {
						document.querySelectorAll('.js-consulTop-slide01 .splide__arrow').forEach(ele => {
							
							if (window.innerWidth >= FUNCTIONS.va.device.desktop) {
								if (ele.parentNode.parentNode.querySelector('.splide__track')) {
									let postop = (ele.parentNode.parentNode.querySelector('.splide__track').clientHeight - ele.clientHeight) / 2;
									ele.style.top = postop + 'px';

									
								}
							} else {
								ele.style.top = '';
							}
							ele.style.opacity = 1;
						});
					};

					setTimeout(() => {
						window.addEventListener('resize', (e) => {
							
							arrowpos();
						});
						document.querySelector('.js-consulTop-slide01').addEventListener('animationend', (event) => {
							arrowpos();
						});
						window.dispatchEvent(new Event('resize'));
					}, 500);
				}
			}
		}

		if (document.querySelector('.js-consulTop-slide02')) {
			let slider = document.querySelector('.js-consulTop-slide02');
			let slideCount = slider.querySelectorAll('.splide__slide').length;

			if (slideCount > 1) {
				document.querySelectorAll('.js-consulTop-slide02').forEach(ele => {
					let perPage = (ele.dataset.perpage) ? Number(ele.dataset.perpage) : 3;
					let focus = (perPage == 2) ? 0 : "center";
					let autoWidth = (perPage == 2) ? false : true;

					let splide = new Splide(ele, {
						type: 'loop',
						perPage: perPage,
						gap: 80,
						autoplay: true,
						autoWidth: autoWidth,
						focus: focus,
						updateOnMove: true,
						interval: 4000,
						speed: 500,
						arrows: true,
						pagination: true,
						paginationKeyboard: true,
						perMove: 1,
						breakpoints: {
							1024: {
								autoWidth: true,
								perPage: 3,
								focus: 0,
								gap: 15,
							},
						}
					}).mount();
				});
				if (document.querySelector('.js-consulTop-slide02 .splide__arrow')) {
					const arrowpos = () => {
						document.querySelectorAll('.js-consulTop-slide02 .splide__arrow').forEach(ele => {
							if (window.innerWidth >= FUNCTIONS.va.device.desktop) {
								if (ele.parentNode.parentNode.querySelector('.is-active.is-visible .consulTop-slide02-item-img')) {
									let postop = (ele.parentNode.parentNode.querySelector('.is-active.is-visible .consulTop-slide02-item-img').clientHeight - ele.clientHeight) / 2;
									ele.style.top = postop + 'px';
								}
							}
							ele.style.opacity = 1;
						});
					};
					const pagenationpos = () => {
						document.querySelectorAll('.js-consulTop-slide02 .splide__track').forEach(ele => {
							let eleHeight = ele.clientHeight;
							ele.style.minHeight = eleHeight + 'px';
						});
					};
					setTimeout(() => {
						window.addEventListener('resize', (e) => {
							

							arrowpos();
							pagenationpos();
						});
						document.querySelector('.js-consulTop-slide02').addEventListener('animationend', (event) => {
							
							arrowpos();
							pagenationpos();
						});
						// リサイズイベントを即時に発火
						window.dispatchEvent(new Event('resize'));
					}, 500);
				}
			}
		}
	}

	const createIchioshiBtns = () => {
		const ichioshiList = document.querySelectorAll(".ichioshi-jihanki li");
		const ichioshiBtnList = document.querySelector(".ichioshi-jihanki-btns");

		if (ichioshiList) {
			ichioshiList.forEach((ele, index) => {
				let cloneNode = ele.querySelector("._vis img").cloneNode(true);
				let liElm = document.createElement("li");

				liElm.dataset.index = index;
				ele.dataset.index = index;

				liElm.appendChild(cloneNode);
				if (index === 0) {
					ichioshiList[0].classList.add(v);
					liElm.classList.add(a);
				}

				if (ichioshiList.length >= 2) {
					liElm.addEventListener('click', e => {
						e.preventDefault();
						let target = e.currentTarget.dataset.index;
						document.querySelectorAll(".ichioshi-jihanki li").forEach(ele => {
							ele.classList.remove(v);
							if (ele.getAttribute("data-index").match(target)) {
								ele.classList.add(v);
								ele.querySelector("._inner > div").style.height = "auto";
							}
						});
						document.querySelectorAll(".ichioshi-jihanki-btns li").forEach(ele => {
							ele.classList.remove(a);
							if (ele.getAttribute("data-index").match(target)) {
								ele.classList.add(a);
							}
						});

					});
					ichioshiBtnList.appendChild(liElm);
				}

			});
		}
	}

	const removeElmIfEmpty = () => {
		const sliderItem = document.querySelectorAll(".rt_bn_news_group_pickup_column ul li");
		const target = document.querySelector(".rt_bn_news_group_pickup_column_is_not_0");
		if (sliderItem) {
			let flag = false;
			sliderItem.forEach(ele => {
				if (ele.innerHTML.trim() !== "") {
					flag = true;
				}
			});
			if (!flag && target) {
				target.classList.add("di_none");
			}
		}
	}

	const countIchioshi = () => {
		const list = document.querySelectorAll(".ichioshi-jihanki li");
		const target = document.querySelector(".ichioshi-jihanki");
		
		if (list) {
			if (list.length === 1) {
				target.classList.add("_single");
			}
			if (list.length === 2) {
				target.classList.add("_double");
			}
		}
	}

	const countCasestudy = () => {
		const list = document.querySelectorAll(".js-casestudy-count");
		if (list) {
			list.forEach((ele, index) => {
				
				
				var caseNumber = String(index + 1).padStart(2, '0');
				ele.textContent = `CASE ${caseNumber}`;
			});
		}
	}

	const setSeminarDate = () => {
		const list = document.querySelectorAll(".js-seminar-date");
		if (list) {
			list.forEach((ele, index) => {
				let startY = ele.querySelector(".rt_cf_n_start_year").textContent;
				let endY = ele.querySelector(".rt_cf_n_end_year").textContent;
				let startM = ele.querySelector(".rt_cf_n_start_month").textContent;
				let endM = ele.querySelector(".rt_cf_n_end_month").textContent;
				let startD = ele.querySelector(".rt_cf_n_start_day").textContent;
				let endD = ele.querySelector(".rt_cf_n_end_day").textContent;
				let startT = ele.querySelector(".rt_cf_n_start_time").textContent;
				let endT = ele.querySelector(".rt_cf_n_end_time").textContent;

				ele.innerHTML = "";
				
				if (startY == endY && startM == endM && startD == endD) {
					let elm = document.createElement("div");
					elm.textContent = `${startY}.${startM}.${startD} ${startT}～${endT}`;
					ele.appendChild(elm);
				} else {
					let elm1 = document.createElement("div");
					let elm2 = document.createElement("div");
					elm1.textContent = `${startY}.${startM}.${startD} ${startT}～`;
					elm2.textContent = `${endY}.${endM}.${endD} ${endT}`;
					ele.appendChild(elm1);
					ele.appendChild(elm2);
				}
			});
		}
	}

	const resetSearchInput = () => {
		if (document.querySelector('.js-search-reset')) {
			document.querySelector('.js-search-reset').addEventListener('click', function (event) {
				event.preventDefault();
				var checkboxInputs = document.querySelectorAll('input[type="checkbox"]');
				checkboxInputs.forEach(function (checkbox) {
					checkbox.checked = false;
				});
			});
		}
	}

	const initSearch = () => {
		if (document.querySelector('.js-news-search-submit')) {
			document.querySelector('.js-news-search-submit').addEventListener('click', function (event) {
				submitNewsSearch();
			});
		}
		if (document.querySelector('.js-casestudy-search-submit')) {
			document.querySelector('.js-casestudy-search-submit').addEventListener('click', function (event) {
				submitCasestudySearch();
			});
		}
		if (document.querySelector('.js-column-search-submit')) {
			document.querySelector('.js-column-search-submit').addEventListener('click', function (event) {
				submitColumnSearch();
			});
		}
	}

	const submitCasestudySearch = () => {
		let queryString = "";

		let selectedCheckboxes = document.querySelectorAll('input[name="casestudy_place"][type="checkbox"]:checked');
		let selectedValues = Array.from(selectedCheckboxes).map(checkbox => checkbox.value);
		if (selectedValues.length > 0) {
			if (queryString !== "") {
				queryString += "&"
				
			}
			queryString += selectedValues.map(category => `casestudy_place=${encodeURIComponent(category)}`).join('&')
			
		}

		selectedCheckboxes = document.querySelectorAll('input[name="casestudy_purpose"][type="checkbox"]:checked');
		selectedValues = Array.from(selectedCheckboxes).map(checkbox => checkbox.value);
		if (selectedValues.length > 0) {
			if (queryString !== "") {
				queryString += "&"
				
			}
			queryString += selectedValues.map(category => `casestudy_purpose=${encodeURIComponent(category)}`).join('&')
			
		}

		// ページ遷移
		const baseUrl = window.location.origin;
		const newUrl = `${baseUrl}/jihankiconsul/casestudy/?${queryString}`;
		window.location.href = newUrl;
	}

	const submitColumnSearch = () => {
		let queryString = "";

		let selectedCheckboxes = document.querySelectorAll('input[name="column"][type="checkbox"]:checked');
		let selectedValues = Array.from(selectedCheckboxes).map(checkbox => checkbox.value);
		if (selectedValues.length > 0) {
			if (queryString !== "") {
				queryString += "&"
				
			}
			queryString += selectedValues.map(category => `column=${encodeURIComponent(category)}`).join('&')
			
		}

		// ページ遷移
		const baseUrl = window.location.origin;
		const newUrl = `${baseUrl}/jihankiconsul/column/?${queryString}`;
		window.location.href = newUrl;
	}

	const submitNewsSearch = () => {
		let queryString = "";

		let selectedCheckboxes = document.querySelectorAll('input[name="jihanki_news"][type="checkbox"]:checked');
		let selectedValues = Array.from(selectedCheckboxes).map(checkbox => checkbox.value);
		if (selectedValues.length > 0) {
			if (queryString !== "") {
				queryString += "&"
				
			}
			queryString += selectedValues.map(category => `jihanki_news=${encodeURIComponent(category)}`).join('&')
			
		}

		// ページ遷移
		const baseUrl = window.location.origin;
		const newUrl = `${baseUrl}/jihankiconsul/news/?${queryString}`;
		window.location.href = newUrl;
	}

	const createRelatedList = () => {
		if (document.querySelector('.js-jihankiconsul-news-id')) {
			let newsId = document.querySelector('.js-jihankiconsul-news-id').textContent;
			let currentUrl = window.location.pathname;
			let pathSegments = currentUrl.split('/');
			let targetSegment = pathSegments[2];

			const getJSONfiles = () => {
				return `/ajax/jihankiconsul/${targetSegment}/all.json?exclude_id=${newsId}`
			}
			if (document.querySelector(".js-jihankiconsul-related-list")) {
				
				fetch(getJSONfiles())
					.then(function (response) {
						return response.json();
					})
					.then(function (jsonData) {
						const tags = document.querySelectorAll(".js-jihankiconsul-news-tags");
						const compareTags = Array.from(tags).map(tag => tag.textContent.trim());
						const sortedNews = jsonData.rt_bn_news_list.sort((a, b) => {
							// タグの一致数をカウントする関数
							const countMatches = (itemTags) => {
								return itemTags.reduce((count, tag) => {
									return count + (compareTags.includes(tag._key) ? 1 : 0);
								}, 0);
							};

							// 各記事のタグ一致数を取得
							const aMatches = countMatches(a.cf_n_tags);
							const bMatches = countMatches(b.cf_n_tags);

							// タグの一致数で比較
							if (aMatches > bMatches) return -1;
							if (aMatches < bMatches) return 1;

							// 一致数が同じ場合、日付で比較 (最新のものが前に来るように)
							const aDate = new Date(a.cf_n_date);
							const bDate = new Date(b.cf_n_date);

							return bDate - aDate;
						});

						
						
						if (jsonData.rt_bn_news_list.length > 0) {
							const html = generateHTML(sortedNews.slice(0, 3), targetSegment);
							document.querySelector('.js-jihankiconsul-related-list').innerHTML = html;
						} else {
							document.querySelector(".js-jihankiconsul-related-list_is_not_0").classList.add("di_none");
						}

						FUNCTIONS.fileSize();
					})
					.catch(error => console.error('Error fetching data:', error));
			}
		}
	}

	const generateHTML = (data, targetSegment) => {
		return data.map(item => `
			<li class="__column_3 __column_tab_3 __column_sp_2">
				<a href="${item['rt_cf_n_href_' + targetSegment]?.href}" target="${item['rt_cf_n_href_' + targetSegment]?.target}" class="corporate-activity-item" target="">
					<figure class="mod-imgWrap-radius10 mod-imgWrap-970_560">
						<img src="${item.rt_cf_n_thum_img2.src}" alt="${item.rt_cf_n_thum_img2.alt || ""}" class="" loading="lazy" width="970" height="560">
						${item.rt_cf_n_new_json !== null ? '<span class="corporate-activity-item-new">NEW</span>' : ''}
					</figure>
					<div class="corporate-activity-item-head mod-spacer_20">
						<time class="corporate-activity-item-head-date">
							${item.rt_cf_n_date.content}
						</time>
					</div>
					<p class="corporate-activity-item-title">
						${item.rt_cf_n_title.content.map(content =>
			typeof content === 'object' && content.tag === 'br'
				? '<br>'
				: content
		).join('')}
						${generateFileInfo(item)}
					</p>
					<ul class="mod-tags _red mod-spacer_20">
						${item.cf_n_tags.map(item => `<li><span>${item.cf_nt_name}</span></li>`).join('')}	
					</ul>
				</a>
			</li>
		`).join('');
	}

	const generateFileInfo = (item) => {
		let fileInfo = '';

		if (item.rt_cf_n_type2_is_json) {
			if (item.rt_cf_n_type2_pdf_is_json) {
				fileInfo += '<svg aria-hidden="true"><use xlink:href="/dydo_common/svg/sprite.svg#pdf"></use></svg>';
			} else if (item.rt_cf_n_type2_pdf_is_not_json) {
				fileInfo += '<svg aria-hidden="true"><use xlink:href="/dydo_common/svg/sprite.svg#document"></use></svg>';
			}
			if (item.rt_cf_n_attachment_file_size) {
				fileInfo += ` <span>(<span class="js-fileSize">${item.rt_cf_n_attachment_file_size.content}</span>)</span>`;
			}
		}

		if (item.rt_cf_n_type3_is_json) {
			if (item.rt_cf_n_type3_ext_link_is_json) {
				fileInfo += '<svg aria-hidden="true"><use xlink:href="/dydo_common/svg/sprite.svg#blank"></use></svg>';
			}
			if (item.rt_cf_n_type3_pdf_is_json) {
				fileInfo += '<svg aria-hidden="true"><use xlink:href="/dydo_common/svg/sprite.svg#pdf"></use></svg>';
			}
			if (item.rt_cf_n_url_file_size) {
				fileInfo += ` <span>(<span class="js-fileSize">${item.rt_cf_n_url_file_size.content}</span>)</span>`;
			}
		}

		return fileInfo;
	}

	const addEventHeader = () => {
		const header = document.querySelector('.header-consul');
		const headerTop = document.querySelector('.header-consul-top');

		if (!header || !headerTop) return;

		const headerHeight = headerTop.offsetHeight;
		// スクロール時の処理関数
		const onScroll = () => {
			if (window.scrollY > headerHeight) {
				header.classList.add('scrolled');
			} else {
				header.classList.remove('scrolled');
			}
		};

		// スクロールイベントのリスナーを追加
		window.addEventListener('scroll', onScroll);
		onScroll();
	}

	countIchioshi();
	countCasestudy();
	newsSlide();
	createIchioshiBtns();
	removeElmIfEmpty();
	setSeminarDate();
	initSearch()
	resetSearchInput();
	createRelatedList();
	addEventHeader();

	// インジケーターアニメーション
	(() => {
		const target = document.querySelectorAll('.js-anim-circle .splide__pagination__page');
		target.forEach(ele => {
			ele.innerHTML = '<svg class="js-dots-circle"><circle cx="12" cy="12" r="9.5"></circle></svg>'

		});
	})();
})(window.FUNCTIONS);
